class SkillInfo {
  constructor(imgUrl, skill, type, yrsOfExp, proficiency) {
    this.imgUrl = imgUrl;
    this.skill = skill;
    this.type = type;
    this.yrsOfExp = yrsOfExp;
    this.proficiency = proficiency;
  }
}
export default SkillInfo;
